import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import store from './store/index.js';
// router setup
import router from './routes/router';
import VueAnalytics from 'vue-analytics';
import VueLazyload from 'vue-lazyload';
import VueMeta from 'vue-meta';
import Rollbar from 'vue-rollbar';


// lazy loading
import 'floating-vue/dist/style.css'
import {
  // Directives
  // VTooltip,
  // VClosePopper,
  // // Components
  // Dropdown,
  Tooltip,
  Menu
} from 'floating-vue'
// Vue.use(FloatingVue)

Vue.use(DashboardPlugin);
Vue.use(VueLazyload);
// import infiniteScroll from 'vue-infinite-scroll';
// Vue.use(infiniteScroll);
// const sweet_alert_options = {
//   confirmButtonColor: 'aquamarine',
//   cancelButtonColor: '#ff7674',
//   background: 'black',
// };
//
Vue.use(VueAnalytics, {
  id: 'UA-42516385-3',
  debug: {
    sendHitTask: process.env.NODE_ENV === 'production',
  },
  router,
});
Vue.use(Rollbar, {
  accessToken:
    process.env.NODE_ENV === 'production'
      ? '21c5ecb565d64811b6622331fa002ab0'
      : '',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: 'production',
  },
});

Vue.use(VueMeta, {
  debounceWait: 7,
});

// Vue.component('VDropdown', Dropdown)
Vue.component('VTooltip', Tooltip)
Vue.component('VMenu', Menu)
/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: (h) => h(App),
  router,
  store,
});
Array.prototype.random = function () {
  return this[Math.floor(Math.random() * this.length)];
};
