import moment from 'moment';

export default Object.freeze([
  // {
  //   title: 'SAX & SAND @ Taboo',
  //   slug: '',
  //   metadata: {
  //     magicEvent: true,
  //     link: '/tulum-clubs/taboo-tulum',
  //     date: moment().endOf('isoWeek').format('YYYY-MM-DD').toLocaleString(),
  //     // date: '2023-12-05',
  //     start_time: '11:00',
  //     main_image: {
  //       imgix_url:
  //         'https://imgix.cosmicjs.com/ee497b20-9394-11ee-8a8d-abf95e574482-image.png',
  //     },
  //   },
  // },
  // {
  //   title: 'SAX & SAND @ Taboo',
  //   slug: '',
  //   metadata: {
  //     magicEvent: true,
  //     link: '/tulum-clubs/taboo-tulum',
  //     date: moment()
  //       .endOf('isoWeek')
  //       .add(5, 'day')
  //       .format('YYYY-MM-DD')
  //       .toLocaleString(),
  //     // date: '2023-12-05',
  //     start_time: '11:00',
  //     main_image: {
  //       imgix_url:
  //         'https://imgix.cosmicjs.com/ee497b20-9394-11ee-8a8d-abf95e574482-image.png',
  //     },
  //   },
  // },
  // {
  //   title: 'Latin Groove Series @ Taboo',
  //   slug: '',
  //   metadata: {
  //     magicEvent: true,
  //     link: '/tulum-clubs/taboo-tulum',
  //     date: moment()
  //       .endOf('isoWeek')
  //       .subtract(1, 'day')
  //       .format('YYYY-MM-DD')
  //       .toLocaleString(),
  //     start_time: '11:00',
  //     main_image: {
  //       imgix_url:
  //         'https://imgix.cosmicjs.com/ec4d9500-939d-11ee-8a8d-abf95e574482-image.png',
  //     },
  //   },
  // },
  // {
  //   title: 'Latin Groove Series @ Taboo',
  //   slug: '',
  //   metadata: {
  //     magicEvent: true,
  //     link: '/tulum-clubs/taboo-tulum',
  //     date: moment()
  //       .endOf('isoWeek')
  //       .add(6, 'day')
  //       .format('YYYY-MM-DD')
  //       .toLocaleString(),
  //     start_time: '11:00',
  //     main_image: {
  //       imgix_url:
  //         'https://imgix.cosmicjs.com/ec4d9500-939d-11ee-8a8d-abf95e574482-image.png',
  //     },
  //   },
  // },
]);
