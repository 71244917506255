export default Object.freeze([
    // Lazarus
    {
      path: '/event/damian-lazarus-mia-tulum-november-2023',
      name: 'lazaruz-bonbo',
      redirect: () => {
        return '/event/damian-lazarus-bonbonniere-tulum-january-2024';
      }
    },
    // Mahamut
    {
      path: '/event/vagalume-wknd-vagalume-tulum-november-3-2023',
      name: 'mahamut',
      redirect: () => {
        return '/event/mahmut-orhan-bagatelle-tulum-january-2024';
      }
    },
    // PLEX
    {
      path: '/event/maceo-plex-bonbonniere-tulum-january-2023',
      name: 'maceo-plex-bonbo',
      redirect: () => {
        return '/event/maceo-plex-bonbonniere-tulum-january-2024';
      }
    },
    // Soundgarden
    {
      path: '/event/nick-warren-2023-the-soundgarden-mia-beach-club-tulum-january',
      name: 'soundgarden-2024',
      redirect: () => {
        return '/event/the-soundgarden-vagalume-tulum-january-2024';
      }
    },
    // TOM AND COLLINS
    {
      path: '/event/tom-collins-bonbonniere-tulum-april-2023',
      name: 'tom-n-collins',
      redirect: () => {
        return '/event/tom-and-collins-bonbonniere-tulum-may-2024';
      }
    },
    // ERAN HERSH
    {
      path: '/event/eran-hersh-bonbonniere-tulum-march-2024',
      name: 'eran-2024',
      redirect: () => {
        return '/event/eran-hersh-bonbonniere-tulum-may-2024';
      }
    },
    ///event/matthias-tanzmann-bonbonniere-tulum-june-3-2023
    {
      path: '/event/matthias-tanzmann-bonbonniere-tulum-june-3-2023',
      name: 'matthias',
      redirect: () => {
        return '/event/matthias-tanzmann-bonbonniere-tulum-may-2024';
      }
    },
    ////event/keinemusik-zamna-tulum-january-2024
    {
      path: '/event/keinemusik-zamna-tulum-january-2024',
      name: 'keinemusik',
      redirect: () => {
        return '/event/keinemusik-zamna-tulum-festival-january-10-2025';
      }
    },
    {
      path: '/event/adriatique-x-zamna-tulum-december-30th-2023',
      name: 'adriatique2025',
      redirect: () => {
        return '/event/adriatique-x-zamna-tulum-festival-january-12-2025';
      }
    },
    {
      path: '/event/boris-brejcha-zamna-tulum-august-2023',
      name: 'boris2025',
      redirect: () => {
        return '/event/boris-brejcha-zamna-tulum-festival-january-02-2025';
      }
    },
    {
      path: '/event/artbat-renaissance-tulum-beach-january-2023',
      name: 'artbat2025',
      redirect: () => {
        return '/event/artbat-upperground-zamna-tulum-festival-december-31-2024';
      }
    },
  ]
)


