import Card from 'src/components/Cards/Card.vue';
import BaseButton from 'src/components/BaseButton.vue';
// import { Input, InputNumber, Tooltip, Popover } from 'element-ui';
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component(Card.name, Card);
    // Vue.component(Input.name, Input);
    // Vue.component(InputNumber.name, InputNumber);
    // Vue.use(Tooltip);
    // Vue.use(Popover);
  },
};

export default GlobalComponents;
